import Swiper, { Navigation, Autoplay } from "swiper";
require("./bootstrap");
require('./cookieinfo.min');
require("featherlight")

const swiperStartseite = new Swiper(".team-swiper-startseite", {
    modules: [Navigation, Autoplay],
    slidesPerView: 1.5,
    spaceBetween: 30,
    autoplay: {
        delay: 3000,
    },
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        480: {
            slidesPerView: 2.5,
            spaceBetween: 40,
        },
        768: {
            slidesPerView: 3.5,
            spaceBetween: 60,
        },
        1200: {
            slidesPerView: 4.5,
            spaceBetween: 85,
        },
    },
});

const swiperTeam = new Swiper(".team-swiper-teamseite", {
    modules: [Navigation, Autoplay],
    slidesPerView: 1.5,
    spaceBetween: 20,
    navigation: {
        nextEl: ".swiper-button-next",
        prevEl: ".swiper-button-prev",
    },
    breakpoints: {
        480: {
            slidesPerView: 2.5,
        },
        768: {
            slidesPerView: 4.5,
        },
        1200: {
            slidesPerView: 6,
        },
    },
});


var app = {

    settings: {},

    init: function () {
        var s = this.settings;
        $ = jQuery;

        // ImageCaptcha
        this.validateCaptcha();
    },

    validateCaptcha: function () {
        $('#submitForm').on('click', function (e) {
            e.preventDefault();

            // check if everything was filled out properly (email field & captcha)
            if (!document.getElementById("contactForm").reportValidity()) {
                return;
            }

            // name of image radio buttons from captcha
            const image_name = "image_captcha"
            const correct_image_name = "image_captcha_correct"

            let numberOfImages = $('input[name="image_captcha"]').length;
            let selectedImage = $('input[name="' + image_name + '"]:checked').val();
            let correctImage = $('input[name="' + correct_image_name + '"]').val();

            // no picture selected
            if (typeof selectedImage == 'undefined') {
                return;
            }

            // prepare data for ajax request
            let data = [
                {
                    name: "ic_input", // user selected image
                    value: selectedImage
                },
                {
                    name: "ic_correct", // correct image
                    value: correctImage
                },
                {
                    name: "ic_amount_of_images", // number of images in captcha
                    value: numberOfImages
                },
                {
                    name: "ic_image_name", // name of image field
                    value: "image_captcha"
                },
                {
                    name: "ic_correct_image_name", // name of correct image field
                    value: "image_captcha_correct"
                }
            ];
            $.ajaxSetup({
                headers: {
                    'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                }
            });
            $.ajax({
                url: "/captcha-controller",
                data: data,
                type: 'GET',
                dataType: 'json',
                success: function (response) {
                    // Check if request was valid.
                    // Set new captcha when validation fails
                    if ( response.success == false ) {
                        $(".fp-image-captcha").html(response.captcha);
                    }
                    else {
                        $("#contactForm").trigger('submit'); // Submit form.
                    }

                    return;
                }
            });
        });
    },
}

jQuery(function ($) {

    'use strict';

    app.init();

})
